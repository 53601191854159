/* eslint-disable */
import React from 'react';
import {Link} from 'react-router-dom';
import {withTranslation} from "react-i18next";

import images from '../../images';
import './LandingPage.css';
import globals from '../../globals';
import {Mobile, MobileLand} from '../../App';
import i18next from '../../i18n'
import LanguageSwitcher from '../../components/LanguageSwitcher';
import {LanguageSelector} from "../../components/LanguageSelector/LanguageSelector";

class LandingPage extends React.Component {
    constructor(props) {
        super(props);
        this.isLandscape = false;
    }

    componentWillMount() {
        this.props.toggleHeader(false);
    }

    render() {
        const {t} = this.props
        return (
            <div  style={{position: "relative"}}>
                <MobileLand>
                    {matches => {
                        if (matches) {
                            this.isLandscape = true;
                            return this._renderMobile(t);
                        } else {
                            return <Mobile>
                                {matches => {
                                    if (matches) {
                                        this.isLandscape = false;
                                        return this._renderMobile(t);
                                    } else return this._renderDesktop(t);
                                }}
                            </Mobile>
                        }
                    }}
                </MobileLand>
            </div>
        );
    }

    _renderMobile(t) {
        const {handleStateUpdate} = this.props;
        return (
            <div className="landing_container">
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        flex: 1,
                        marginTop: 30
                    }}
                >
                    <img
                        style={{position: 'absolute', left: 0, right: 0, top: 0,}}
                        src={images().lpTopImageMob}
                        className="lpBgTopMob"
                    />
                    <div
                        style={{
                            display: 'flex',
                            position: 'absolute',
                            left: 0,
                            right: 0,
                        }}
                    >
                        <img className="logoMWAHeader" src={images().logo}/>
                        <div className="header_item_title" style={{flexGrow: 1}}>
                            <div
                                className="headerTitleLabel"
                                style={{margin: 0, padding: 0}}
                            >
                                {t('MyWaterAdvisor')}
                            </div>
                            <div
                                className="headerSubtitleLabelMob"
                                style={{margin: 0, padding: 0}}
                            >
                                {t('OnlineYourWorldWater')}
                            </div>
                        </div>
                        <LanguageSelector />
                    </div>
                    <img
                        src={i18next.language === 'he' ? images().lpImage : images().lpImageEn}
                        className="lpBigImageMob"
                        style={{marginTop: 100, zIndex: 1}}
                    />
                    <div className="lpMonitorLabelMob" style={{color: '#3c63d7'}}>
                        {t('MonitorRealTimeConsumption')}
                    </div>
                    <div
                        style={{
                            marginTop: this.isLandscape ? 140 : 40,
                            marginBottom: 40,
                            display: 'flex',
                            alignItems: 'center',
                            zIndex: 1000,
                            marginLeft: 15,
                            marginRight: 15,
                        }}
                    >
                        <Link to={'/register'} onClick={()=> handleStateUpdate({currentScreen: 0})} className="JoinButton">{t('JoinButton')}</Link>
                        <div className="LandingButtonDivider"/>
                        <Link to={'/login'} onClick={()=> handleStateUpdate({currentScreen: 0})} className="LoginButton">{t('Login').toUpperCase()}</Link>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <img
                            src={images().icEmpowerment}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div className="lpButtonTitle" style={{marginTop: 10}}>
                            {t('Empowerment')}
                        </div>
                        <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                            {t('KnowledgeOfHowUseWaterEmpower')}
                        </div>
                    </div>

                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images().icFlexibility}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{marginTop: 10}}
                        >
                            {t('Flexibility')}
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{marginTop: 10}}
                        >
                            {t('AppsAvailableForBothApple')}
                        </div>
                    </div>

                    <div
                        style={{
                            flexDirection: 'column',
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images().icCustomerService}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div
                            className="lpButtonTitle"
                            style={{marginTop: 10}}
                        >
                            {t('CustomerService')}
                        </div>
                        <div
                            className="lpButtonSubTitle"
                            style={{marginTop: 10}}
                        >
                            {t('SetBudget')}
                        </div>
                    </div>

                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: 20
                        }}
                    >
                        <img
                            src={images().icSafety}
                            className="lpImage"
                            style={{width: 30, height: 30}}
                        />
                        <div className="lpButtonTitle" style={{marginTop: 10}}>
                            {t('Safety')}
                        </div>
                        <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                            {t('MonitorUnexpected')}
                        </div>
                    </div>
                    <div
                        style={{
                            flexDirection: 'column',
                            alignItems: 'center',
                            display: 'flex',
                            marginTop: 20
                        }}
                    >

                    </div>
                    <div className="alsoAvailableMob">
                        {t('mobileApps')}
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignSelf: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                alignItems: 'center'
                            }}
                            onClick={() => window.open(globals.IOS_LINK, '_blank')}
                        >
                            <img className="lpButtonMob" src={images().btnAppStore}/>
                        </button>
                        <button
                            style={{
                                backgroundColor: 'transparent',
                                alignItems: 'center'
                            }}
                            onClick={() =>
                                window.open(globals.ANDROID_LINK, '_blank')
                            }
                        >
                            <img
                                className="lpButtonMob"
                                src={images().btnGooglePlay}
                            />
                        </button>
                    </div>
                    {/*<div style={{display: 'flex', marginBottom: 100, zIndex: -1}}>*/}
                    {/*    <img*/}
                    {/*        src={images().bgWaveMob}*/}
                    {/*        style={{position: 'absolute', width: '100%'}}*/}
                    {/*    />*/}
                    {/*</div>*/}
                    <div className="Landing_BottomLogo">
                        <div style={{justifyContent: 'center'}}>
                            <img className="logoMWAHeader" src={images().logo} alt="logo"/>
                        </div>
                        <div className="Landing_Bottom_TitleLabel">
                            {t('MyWaterAdvisor')}
                        </div>
                        <div className="Landing_Bottom_SubtitleLabel">
                            {t('OnlineYourWorldWater')}
                        </div>
                    </div>
                    <img src={images().bgWaveMob} alt="" className="Landing_Bottom_background"/>
                </div>
            </div>
        );
    }

    _renderDesktop(t) {
        const {handleStateUpdate} = this.props
        const {
            lpTopImage,
            logo,
            lpImage,
            lpImageEn,
            bgWave,
            icEmpowerment,
            icFlexibility,
            icCustomerService,
            icSafety,
            btnAppStore,
            btnGooglePlay,
            bgWaveBottom
        } = images();

        return (
            <div style={{position: 'relative', display: 'flex', flexDirection: 'column', marginTop: 74}}>
                <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                    <div style={{display: 'flex', flex: 1, columnGap: 15, minHeight: '70vh', direction: 'ltr', position: 'relative'}}>
                        <div style={{position: 'absolute', top: '70%', left: 0, right: 0}}>
                            <img src={bgWave} alt="" style={{width: '100%'}} />
                        </div>
                        <div style={{width: '60%', position: 'relative'}}>
                            <div style={{position: 'absolute', top: 0, left: 0}} >
                                <img src={lpTopImage} alt="logo" />
                                <div style={{position: 'absolute', display: 'flex', top: 0, left: 0, width: '100%', height: '100%'}} >
                                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                                        <div style={{display: 'flex', flexDirection: 'column', width: '90%'}}>
                                            <div style={{display: 'flex', flexDirection: 'column', marginTop: 15}}>
                                                <div style={{display: 'flex', height: 80}}>
                                                    <img src={logo} alt="" style={{height: '100%'}}/>
                                                    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-around', marginLeft: 15}}>
                                                        <h1 className="headerTitleLabel" style={{margin: 0}}>
                                                            {t('MyWaterAdvisor')}
                                                        </h1>
                                                        <span className="headerSubtitleLabel">{t('OnlineYourWorldWater')}</span>
                                                    </div>
                                                </div>
                                                <div style={{marginTop: 15}}>
                                                    <img src={i18next.language === 'he' ? lpImage : lpImageEn} alt="logo" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{width: '40%', direction: i18next.dir()}}>
                            <div style={{display: 'flex', height: '100%', flexDirection: 'column', marginTop: 15, marginRight: 15}}>
                                <div style={{display: 'flex', justifyContent: 'center'}}>
                                    <LanguageSwitcher color='black' />
                                </div>
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', flex: 1}}>
                                <span className="lpMonitorLabel" style={{color: '#3c63d7'}}>
                                    {t('MonitorRealTimeConsumption')}
                                </span>
                                    <div className="lpButtonsContainer">
                                        <Link to={'/register'} onClick={()=> handleStateUpdate({currentScreen: 0})} className="JoinButton">
                                            {t('JoinButton')}
                                        </Link>
                                        <div className="LandingButtonDivider"/>
                                        <Link to={'/login'} onClick={()=> handleStateUpdate({currentScreen: 0})} className="LoginButton">
                                            {t('Login').toUpperCase()}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 180}}>
                        <div style={{
                            display: 'flex',
                            alignSelf: 'center',
                            alignItems: 'center'
                        }}>
                            <div style={{
                                flexDirection: 'column',
                                alignItems: 'center',
                                display: 'flex'
                            }}>
                                <img
                                    src={icEmpowerment}
                                    className="lpImage"
                                    style={{width: 40, height: 40}}
                                />
                                <div className="lpButtonTitle" style={{marginTop: 10}}>
                                    {t('Empowerment')}
                                </div>
                                <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                                    {t('KnowledgeOfHowUseWaterEmpower')}
                                </div>
                            </div>
                            <div
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginLeft: 20
                                }}
                            >
                                <img
                                    src={icFlexibility}
                                    className="lpImage"
                                    style={{width: 40, height: 40}}
                                />
                                <div className="lpButtonTitle" style={{marginTop: 10}}>
                                    {t('Flexibility')}
                                </div>
                                <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                                    {t('AppsAvailableForBothApple')}
                                </div>
                            </div>
                        </div>
                        <div style={{
                            display: 'flex',
                            alignSelf: 'center',
                            alignItems: 'center',
                            marginTop: 60,
                        }}>
                            <div
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    display: 'flex',
                                    marginLeft: 20
                                }}
                            >
                                <img
                                    src={icCustomerService}
                                    className="lpImage"
                                    style={{width: 40, height: 40}}
                                />
                                <div className="lpButtonTitle" style={{marginTop: 10}}>
                                    {t('CustomerService')}
                                </div>
                                <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                                    {t('SetBudget')}
                                </div>
                            </div>
                            <div
                                style={{
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    display: 'flex'
                                }}
                            >
                                <img
                                    src={icSafety}
                                    className="lpImage"
                                    style={{width: 40, height: 40}}
                                />
                                <div className="lpButtonTitle" style={{marginTop: 10}}>
                                    {t('Safety')}
                                </div>
                                <div className="lpButtonSubTitle" style={{marginTop: 10}}>
                                    {t('MonitorUnexpected')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 180}}>
                        <div
                            style={{
                                display: 'flex',
                                alignSelf: 'center',
                            }}
                            className="alsoAvailable"
                        >
                            {t('mobileApps')}
                        </div>
                        <div
                            style={{
                                flexDirection: 'row',
                                display: 'flex',
                                marginTop: 20,
                                alignSelf: 'center'
                            }}
                        >
                            <button
                                style={{
                                    backgroundColor: 'transparent',
                                    alignItems: 'center'
                                }}
                                onClick={() => window.open(globals.IOS_LINK, '_blank')}
                            >
                                <img className="lpButton" src={btnAppStore}/>
                            </button>
                            <button
                                style={{
                                    backgroundColor: 'transparent',
                                    alignItems: 'center'
                                }}
                                onClick={() =>
                                    window.open(globals.ANDROID_LINK, '_blank')
                                }
                            >
                                <img className="lpButton" src={btnGooglePlay}/>
                            </button>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginTop: 300, marginBottom: 200, position: 'relative'}}>
                        <div className="Landing_BottomLogo">
                            <div style={{justifyContent: 'center', zIndex: 1}}>
                                <img className="logoMWAHeader" src={logo} alt="logo"/>
                            </div>
                            <div className="Landing_Bottom_TitleLabel">
                                {t('MyWaterAdvisor')}
                            </div>
                            <div className="Landing_Bottom_SubtitleLabel">
                                {t('OnlineYourWorldWater')}
                            </div>
                        </div>
                    </div>
                    <img src={bgWaveBottom} alt="" className="Landing_Bottom_background" />
                </div>
            </div>
        );
    }

    componentWillUnmount() {
        this.props.toggleHeader(true);
    }
}


export default withTranslation('translations')(LandingPage)
