import React from "react";
import { withTranslation } from "react-i18next";

import "./Header.css";
import images from "../../images";
import { Mobile } from "../../App";
import LanguageSwitcher from "../../components/LanguageSwitcher";

class Header extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <Mobile>
        {(matches) => {
          if (matches) return this._renderMobile(t);
          else return this._renderDesktop(t);
        }}
      </Mobile>
    );
  }

  _renderDesktop(t) {
    return (
      <div className="header">
        <div className="header_item">
          <div style={{ justifyContent: "center" }}>
            <img className="logoMWAHeader" src={images().logo} alt="Logo" />
          </div>
          <div className="header_item_title">
            <div className="headerTitleLabel" style={{ margin: 0, padding: 0 }}>
              {t("MyWaterAdvisor")}
            </div>
            <div
              className="headerSubtitleLabel"
              style={{ margin: 0, padding: 0 }}
            >
              {t("OnlineYourWorldWater")}
            </div>
          </div>
        </div>
        <div className="header_item">
          <LanguageSwitcher />
        </div>
      </div>
    );
  }

  _renderMobile(t) {
    return (
      <div className="header headerMobile">
        <div className="header_item">
          <div style={{ justifyContent: "center" }}>
            <img className="logoMWAHeader" src={images().logo} alt="Logo" />
          </div>
          <div className="header_item_title">
            <div
              className="headerTitleLabel"
              style={{ fontSize: 18, margin: 0, padding: 0 }}
            >
              {t("MyWaterAdvisor")}
            </div>
            <div
              className="headerSubtitleLabel"
              style={{
                fontSize: 18,
                margin: 0,
                padding: 0,
              }}
            >
              {t("OnlineYourWorldWater")}
            </div>
          </div>
        </div>
        <div className="header_item">
          <LanguageSwitcher />
        </div>
      </div>
    );
  }
}

export default withTranslation("translations")(Header);
