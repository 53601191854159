import React from "react";
import Popup from "reactjs-popup";
import * as FileSaver from "file-saver";
import moment from "moment";
import { withTranslation } from "react-i18next";
import DatePicker from "react-date-picker";

import "./Home.css";
import "./DatePicker.css";
import utils from "../../utils";
import {AlertsTitles} from "../../utils";
import * as api from "../../api";
import { Mobile } from "../../App";
import MonthlyLimitPopup from "./MonthlyLimitPopup";
import images from "../../images";
import DefineVacationPopup from "./DefineVacationPopup";
import globals from "../../globals";
import MeterChart from "../../components/MeterChart";
import MeterSelection from "../../components/MeterSelection";
import CalendarRangePicker from "../../components/CalendarRangePicker";
import AppPopup from "../../components/AppPopup";
import i18next from "../../i18n";

class Home extends React.Component {
  constructor(props) {
    super(props);

    this.today = new Date();

    this.state = {
      isLoading: false,
      startDate: moment(this.today).subtract(11, "months").startOf("month").locale('en'), //1
      endDate: moment(this.today).endOf("month").locale('en'),
      dailyStartDate: moment().subtract(31, "days").locale('en'),
      dailyEndDate: moment().locale('en'),
      monthlyDefaultStartDate: moment(this.today).subtract(11, "months").locale('en'),
      monthlyDefaultEndDate: this.today,
      dailyDefaultStartDate: moment(this.today).subtract(1, "months").locale('en'),
      dailyDefaultEndDate: this.today,
      currentMeter: null,
      thisMonthUsage: "",
      forecastUsage: "",
      monthlyLimit: "",
      alert: undefined,
      openMonthlyLimit: false,
      openDefineVacation: false,
      openMeterSelection: false,
      openCalendarPicker: false,
      selectedChartTypeIndex: 0,
      selectedChartTimeTypeIndex: 0,
      dailyConsumptions: [],
      monthlyConsumptions: [],
      presentationMode: "",
      monthlyConsumptionsForNeighborhood: [],
      hourlyConsumptions: [],
      yearsConsumptions: [],
      neighborConsumptions: [],
      chartShape: "bar",
      errorMessage: null,
      isDefault: true,
      isBigNumbers: false,
      billingCycles: [
        { type: "current", billingCycleStart: "", billingCycleEnd: "" },
        {
          type: "previous",
          billingCycleStart: "",
          billingCycleEnd: "",
        },
      ],
      chartTypes: ["WATER CONSUMPTION", "COMPARE TO PREVIOUS YEAR"],
    };

    this.chartTimeTypes = ["Monthly", "Daily"];
  }

  componentDidMount() {
    if (this.props.meters.length === 1) {
      this.onMeterSelected(this.props.meters[0]);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (JSON.stringify(prevProps.meters) !== JSON.stringify(this.props.meters)) {
      if (this.props.meters.length === 1) {
        this.onMeterSelected(this.props.meters[0]);
      }
    }
  }

  getClassName = () => {
    if (this.state.forecastUsage < 0) return "endOfMonthForecastValue OverLimit";

    return this.state.monthlyLimit
      ? this.state.monthlyLimit < this.state.forecastUsage
        ? "endOfMonthForecastValue OverLimit"
        : "endOfMonthForecastValue Limit"
      : "endOfMonthForecastValue";
  };

  _renderDesktop(t, meterDesc, meterSn) {
    const billingCycleText = utils.toMonthDate(new Date());
    const { units, meters } = this.props;
    const {
      selectedChartTimeTypeIndex,
      selectedChartTypeIndex,
      startDate,
      endDate,
      dailyStartDate,
      dailyEndDate,
    } = this.state;

    let chartData;
    switch (selectedChartTypeIndex) {
      case 2: {
        //Year
        chartData = this.state.monthlyConsumptionsForNeighborhood;
        break;
      }
      default: {
        //My
        switch (selectedChartTimeTypeIndex) {
          case 1:
            //Day
            chartData = this.state.dailyConsumptions;
            break;
          default:
            //Month
            chartData = this.state.monthlyConsumptions;
        }
      }
    }

    if (typeof chartData === "object" && !Array.isArray(chartData) && chartData !== null) {
      chartData = [];
    }

    console.log('this.props?.alerts', this.props?.alerts);

    return (
      <div className="desktopHomeContent">
        <div
          style={{
            display: "flex",
            marginBottom: 30,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              minWidth: "40%",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="waterMetersButton"
                style={{ flexDirection: "column" }}
              >
                <div className="myWaterUsage">{t("BillingCycle")}</div>
                <div className="myWaterUsageDate">{billingCycleText}</div>
              </div>
              <div className="waterMetersButton">
                <div className="waterUsageSep" />
                <button
                  disabled={meters.length < 2}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    cursor: meters.length < 2 ? "default" : "initial",
                  }}
                  onClick={() => this.setState({ openMeterSelection: true })}
                >
                  <div className="waterMeterTitle">
                    {t("WaterMeter") + " " + meterSn}
                  </div>
                  <div className="waterMeterAddress">
                    <img src={images().icLocation} alt="meter location" />
                    <div className="waterMeterValue">{meterDesc}</div>
                  </div>
                </button>
              </div>
            </div>

            <div className="waterUsageSepHor" />

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                marginBottom: 20,
                width: "100%",
              }}
            >
              <div style={{ display: "flex" }}>
                {(meters.length === 1 || this.state?.currentMeter !== null) && (
                  <div className="waterMetersButton">
                    {/* <div style={{ width: 20 }} /> */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <div className="waterMeterTitle">
                        {t("CurrentMeterReading")}
                      </div>
                      <div style={{ display: "flex" }}>
                        <div className={"thisMonthValue"} style={this.state.isBigNumbers ? {"font-size": "38px"} : {}}>
                          {/* {JSON.stringify(this.state.currentMeter)} */}
                          {this.state?.currentMeter !== null
                            ? utils.getFormattedConsumption(
                                this.state?.currentMeter?.read
                              )
                            : meters.length === 1
                            ? utils.getFormattedConsumption(meters[0].read)
                            : null}
                        </div>
                        <div className="endOfMonthForecastUnits">
                          {(this.state?.currentMeter !== null ||
                            meters.length === 1) &&
                            units?.toLowerCase()}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <div
                  className="waterMetersButton"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="waterMeterTitle">
                    {t("CurrentBillingCycle")}
                  </div>
                  <div style={{ display: "flex" }}>
                    <div
                      className={
                        this.state.monthlyLimit &&
                        this.state.thisMonthUsage > this.state.monthlyLimit
                          ? "monthlyLimitValueLabel"
                          : this.state.thisMonthUsage < 0 ? "monthlyLimitValueLabel" : "thisMonthValueGreen"
                      }
                      style={this.state.isBigNumbers ? {"font-size": "38px", direction: 'ltr'} : {direction: 'ltr'}}
                    >
                      {utils.getFormattedConsumption(this.state.thisMonthUsage)}
                    </div>
                    <div className="endOfMonthForecastUnits">
                      {units?.toLowerCase()}
                    </div>
                  </div>
                </div>
                <div className="waterMetersButton">
                  {/* <div style={{ width: 20 }} /> */}
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="waterMeterTitle">
                      {t("EndOfBillingCycleForecast")}
                    </div>
                    <div style={{ display: "flex" }}>
                      <div className={this.getClassName()} style={this.state.isBigNumbers ? {"font-size": "38px", direction: 'ltr'} : {direction: 'ltr'}}>
                        {utils.getFormattedConsumption(
                          this.state.forecastUsage
                        )}
                      </div>
                      <div className="endOfMonthForecastUnits">
                        {units?.toLowerCase()}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div className="monthlyLimitLabel">
                {t("BillingCycleLimit") +
                  " " +
                  this.state.monthlyLimit +
                  " " +
                  units?.toLowerCase()}
              </div>
              {/*<div className="monthlyLimitValue">*/}
              {/*    {this.state.monthlyLimit}*/}
              {/*</div>*/}
              {/*<div className="monthlyLimitHomeUnits">*/}
              {/*    {units?.toLowerCase()}*/}
              {/*</div>*/}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              maxWidth: 470,
              width: 10,
              flex: 1,
              marginLeft: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
                alignItems: "center",
                paddingTop: 40,
                paddingBottom: 40,
                paddingLeft: 25,
                paddingRight: 25,
              }}
            >
              <div className="getInformed">{t("getInformed")}</div>
              {this.props.vacation ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="vacationDates">
                    {utils.toVacationDate(this.props.vacation.startDate)} -{" "}
                    {utils.toVacationDate(this.props.vacation.endDate)}
                  </div>
                  <div style={{ display: "flex" }}>
                    <p
                      className="link"
                      onClick={this.openDefineVacation.bind(this)}
                    >
                      {t("Update")}
                    </p>
                    <p
                      className="link"
                      onClick={this.handleCancelVacation.bind(this)}
                    >
                      {t("CancelVacation")}
                    </p>
                  </div>
                </div>
              ) : (
                <button
                  className="blueButton btnDefineDays"
                  onClick={this.openDefineVacation.bind(this)}
                >
                  {t("btnDefineDays").toUpperCase()}
                </button>
              )}
            </div>
          </div>
        </div>
        {this.props?.alerts.map((item, index) => {
          return (
            <div
              key={"alert" + index}
              style={{
                display: "flex",
                backgroundColor: globals.COLOR.BLUSH,
                padding: 20,
                marginBottom: 4,
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={utils.getSmallIconForAlertType(item.alertTypeId)}
                    style={{
                      width: 50,
                      height: 50,
                      resizeMode: "contain",
                    }}
                    alt="Alert Icon"
                  />
                  <div className="alertInfoContainer">
                    <div className="alertInfoType">{t(`AlertsTitles.${AlertsTitles[+item.alertTypeId] ?? ''}`)}</div>
                    <div style={{ display: "flex" }}>
                      <div className="alertDescriptionWaterMeter">
                        {t("WaterMeter")}&nbsp;
                      </div>
                      <div className="alertDescription">{`${
                        item?.meterSn || ""
                      }, ${item?.fullAddress || ""}`}</div>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "flex-end",
                  }}
                >
                  <div
                    style={{
                      alignItems: "flex-end",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="alertDate">
                      {utils.toAlertDate(item.alertTime)}
                    </div>
                    <button
                      className="seeDetailsButton"
                      onClick={() => this.onOpenAlert(item)}
                    >
                      {t("seeDetails")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <div
          style={{
            display: "flex",
            width: "100%",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          {this.state.chartTypes.map((item, index) => {
            return (
              <button
                key={"chartTypeButton" + index}
                onClick={() => this.onChartTypeChange(index)}
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "stretch",
                  padding: 0,
                }}
              >
                <div
                  style={{
                    textAlign: "center",
                    width: "100%",
                    fontWeight: "700",
                    fontSize: 18,
                    color:
                      this.state.selectedChartTypeIndex === index
                        ? globals.COLOR.AZURE
                        : globals.COLOR.COOL_GRAY,
                  }}
                >
                  {this.renderTabHeader(item, t)}
                </div>
                <div
                  style={{
                    marginTop: 10,
                    height: 2,
                    backgroundColor:
                      this.state.selectedChartTypeIndex === index
                        ? globals.COLOR.AZURE
                        : globals.COLOR.COOL_GRAY,
                  }}
                />
              </button>
            );
          })}
        </div>
        <div
          className="chartBg"
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          {this.state.selectedChartTypeIndex === 0 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "30%",
                  height: 50,
                }}
                className="chartTypesBg"
              >
                {this.chartTimeTypes.map((item, index) => {
                  return (
                    <button
                      key={"chartTimeButton" + index}
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: 0,
                        position: "relative",
                      }}
                      onClick={() => this.onChartTimeChange(index)}
                    >
                      <div
                        className={
                          this.state.selectedChartTimeTypeIndex === index
                            ? "chartTypesBg"
                            : ""
                        }
                        style={{
                          display: "flex",
                          position: "absolute",
                          top: -3,
                          left: -3,
                          right: -3,
                          bottom: -3,
                          fontSize: 14,
                          alignItems: "center",
                          justifyContent: "center",
                          color:
                            this.state.selectedChartTimeTypeIndex === index
                              ? "#fff"
                              : globals.COLOR.BROWNISH_GRAY,
                          backgroundColor:
                            this.state.selectedChartTimeTypeIndex === index
                              ? globals.COLOR.VIBRANT_BLUE
                              : "transparent",
                        }}
                      >
                        {t(item)}
                      </div>
                    </button>
                  );
                })}
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: 24,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "50%",
                    marginLeft: 20,
                    marginRight: 20,
                  }}
                >
                  <div className="fromTo">{t("from")}</div>
                  <DatePicker
                    onChange={(startDate) => {
                      this.closeCalendarPicker(
                        startDate,
                        !selectedChartTimeTypeIndex
                          ? moment(endDate).diff(startDate, "months") > 12
                            ? moment(startDate).add(12, "month")
                            : moment(endDate).diff(startDate, "months") < 0
                            ? startDate
                            : endDate
                          : moment(dailyEndDate).diff(startDate, "days") > 30
                          ? moment(startDate).add(31, "days")
                          : moment(dailyEndDate).diff(startDate, "days") < 0
                          ? startDate
                          : dailyEndDate
                      );
                    }}
                    closeCalendar={false}
                    clearIcon={null}
                    locale={i18next.language}
                    maxDetail={!selectedChartTimeTypeIndex ? "year" : "month"}
                    format={
                      !selectedChartTimeTypeIndex ? "yyyy,MMM" : "yyyy,MMM, d"
                    }
                    value={utils.toDateFromString(
                      !selectedChartTimeTypeIndex ? startDate : dailyStartDate
                    )}
                    calendarIcon={
                      <img src={images().icSmallCalendar} alt="from" />
                    }
                    maxDate={new Date()}
                    minDetail={"year"}
                  />
                </div>
                {
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <div className="fromTo">{t("to")}</div>

                    <DatePicker
                      onChange={(endDate) => {
                        this.closeCalendarPicker(
                          !selectedChartTimeTypeIndex
                            ? moment(endDate).diff(startDate, "months") > 12
                              ? moment(endDate).subtract(12, "month")
                              : startDate
                            : moment(endDate).diff(dailyStartDate, "days") > 30
                            ? moment(endDate).subtract(31, "days")
                            : dailyStartDate,
                          endDate
                        );
                      }}
                      clearIcon={null}
                      locale={i18next.language}
                      maxDetail={!selectedChartTimeTypeIndex ? "year" : "month"}
                      format={
                        !selectedChartTimeTypeIndex ? "yyyy,MMM" : "yyyy,MMM, d"
                      }
                      value={utils.toDateFromString(
                        !selectedChartTimeTypeIndex ? endDate : dailyEndDate
                      )}
                      calendarIcon={
                        <img src={images().icSmallCalendar} alt="to" />
                      }
                      maxDate={new Date()}
                      minDate={utils.toDateFromString(
                        !selectedChartTimeTypeIndex ? startDate : dailyStartDate
                      )}
                      minDetail={"year"}
                    />
                  </div>
                }
              </div>
            </div>
          )}
          {this.state.selectedChartTypeIndex === 0 && (
            <div
              style={{
                justifyContent: "space-between",
                marginTop: 20,
                marginLeft: 30,
                marginRight: 30,
              }}
              className="chartActions"
            >
              <div style={{ display: "flex" }}>
                <button onClick={() => this.setState({ chartShape: "bar" })}>
                  <img
                    src={
                      this.state.chartShape === "bar"
                        ? images().icBarChartSelected
                        : images().icBarChart
                    }
                    alt="Bar chart"
                  />
                </button>
                <button onClick={() => this.setState({ chartShape: "table" })}>
                  <img
                    style={{ width: 20, height: 20 }}
                    src={
                      this.state.chartShape === "table"
                        ? images().icTableChartSelected
                        : images().icTableChart
                    }
                    alt="Table chart"
                  />
                </button>
              </div>
              <div style={{ display: "flex" }}>
                <button onClick={() => this.onPrint()}>
                  <img src={images().icPrinter} alt="print" />
                </button>
                <button onClick={() => this.onExcel()}>
                  <img src={images().icExcel} alt="download excel" />
                </button>
              </div>
            </div>
          )}
          <MeterChart
            selectedMeter={this.state.currentMeter}
            units={units}
            startDate={selectedChartTimeTypeIndex ? dailyStartDate : startDate}
            endDate={selectedChartTimeTypeIndex ? dailyEndDate : endDate}
            neighborData={this.state.neighborConsumptions}
            type={this.state.selectedChartTypeIndex}
            timeType={selectedChartTimeTypeIndex}
            data={chartData ? chartData?.sort(
                    (a, b) =>
                      moment(
                        a.consDate
                      ).unix() -
                      moment(b.consDate).unix()
                  ) : []}
            monthlyLimit={this.state.monthlyLimit}
            yearsConsumptions={this.state.yearsConsumptions}
            style={{ marginTop: 16 }}
            chartShape={this.state.chartShape}
          />
        </div>

        <Popup
          contentStyle={{ borderRadius: 8, width: "30%" }}
          closeOnDocumentClick={false}
          open={this.state.openMonthlyLimit}
        >
          <MonthlyLimitPopup
            monthlyLimit={this.state.monthlyLimit}
            onClose={this.closeMonthlyLimit.bind(this)}
          />
        </Popup>

        <Popup
          contentStyle={{ borderRadius: 8, width: "initial" }}
          open={this.state.openDefineVacation}
          closeOnDocumentClick={false}
        >
          <DefineVacationPopup
            {...this.props}
            vacation={this.props.vacation}
            currentMeter={this.state.currentMeter}
            onClose={this.closeDefineVacation.bind(this)}
          />
        </Popup>

        <Popup
          contentStyle={{ borderRadius: 8 }}
          closeOnDocumentClick={false}
          open={this.state.openMeterSelection}
          onClose={this.closeMeterSelection.bind(this)}
        >
          <MeterSelection
            waterMeters={meters}
            onMeterSelected={this.onMeterSelected.bind(this)}
            onClose={this.closeMeterSelection.bind(this)}
          />
        </Popup>

        <Popup
          closeOnDocumentClick={false}
          contentStyle={{ borderRadius: 8 }}
          open={this.state.openCalendarPicker}
        >
          <CalendarRangePicker
            isMonthSelection={this.state.selectedChartTimeTypeIndex === 0}
            singleDay={this.state.selectedChartTimeTypeIndex === 2}
            startDate={
              this.state.startDate
                ? utils.toDateFromString(this.state.startDate)
                : null
            }
            endDate={
              this.state.endDate
                ? utils.toDateFromString(this.state.endDate)
                : null
            }
            onClose={this.closeCalendarPicker.bind(this)}
          />
        </Popup>

        <Popup
          contentStyle={{ borderRadius: 8 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>

        {/* {true && (
          <div
            style={{
              position: "absolute",
              top: 0,
              bottom: 0,
              right: 0,
              left: 0,
              flex: 1,
              backgroundColor: "black",
              opacity: 0.3,
              flex: 1,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: '100%'
            }}
          >
            <ClipLoader
              style={{ marginTop: 10, alignSelf: "center" }}
              color={"white"}
              loading={true}
            />
          </div>
        )} */}

        {/*<ReactToPrint*/}
        {/*trigger={() => this.printButtonRef}*/}
        {/*content={() => this.chartRef}*/}
        {/*/>*/}
      </div>
    );
  }

  renderTabHeader(item, t) {
    switch (item) {
      case "WATER CONSUMPTION":
        return t("waterConsumption");

      case "COMPARE TO PREVIOUS YEAR":
        return t("compareToPreviousYear");

      case "ME VS. SIMILAR HOUSEHOLDS":
        return t("tab2Heading");

      default:
        return t("compareToPreviousYear");
    }
  }

  closeErrorPopup() {
    this.setState({ errorMessage: null });
  }

  onChooseDate() {
    this.setState({ openCalendarPicker: true });
  }

  closeCalendarPicker = (startDate, endDate) => {
    const { currentMeter, selectedChartTimeTypeIndex } = this.state;

    if (selectedChartTimeTypeIndex) {
      this.setState(
        {
          dailyStartDate: moment(startDate).locale('en').format("YYYY-MM-DD"),
          dailyEndDate: moment(endDate).locale('en').format("YYYY-MM-DD"),
        },
        () => {
          currentMeter
            ? this._getDataForMeter(currentMeter, false)
            : this._getDataForAllMeters(false);
        }
      );
    } else {
      this.setState(
        {
          startDate: moment(startDate).locale('en').format("YYYY-MM-DD"),
          endDate: utils.getEndOfMonthDate(endDate),
        },
        () => {
          currentMeter
            ? this._getDataForMeter(currentMeter, false)
            : this._getDataForAllMeters(false);
        }
      );
    }
  };

  onExcel = () => {
    if (this.state.selectedChartTimeTypeIndex === 0) {
      //Monthly
      api.getAllMonthlyConsumptionExcel(
        this.state?.currentMeter?.meterCount ?? null,
        utils.toDateString(this.state.startDate),
        utils.toDateString(this.state.endDate),
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              FileSaver.saveAs(res, "monthly_consumption.csv");
            }
          }
        }
      );
    } else if (this.state.selectedChartTimeTypeIndex === 1) {
      //Daily
      // if (this.state.isDefault) {
      //     api.getAllDailyConsumptionExcelBasedOnBillingCycle(
      //         (res, err) => {
      //             if (err) {
      //                 console.log('Unknown error has occurred.');
      //             } else {
      //                 if (res.error) {
      //                     console.log(res.error);
      //                 } else {
      //                     FileSaver.saveAs(res, 'daily_consumption.csv');
      //                 }
      //             }
      //         }
      //     );
      // } else {
      api.getAllDailyConsumptionExcel(
        this.state?.currentMeter?.meterCount ?? null,
        utils.toDateString(this.state.dailyStartDate),
        utils.toDateString(this.state.dailyEndDate),
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              FileSaver.saveAs(res, "daily_consumption.csv");
            }
          }
        }
      );
      // }
    }
  };

  onPrint = () => {
    window.print();
  };

  onMeterSelected = (meter) => {
    const chartTypes = ["WATER CONSUMPTION", "COMPARE TO PREVIOUS YEAR"];
    console.log("onMeterSelected", meter);
    this.setState({
      isBigNumbers: false,
      openMeterSelection: false,
      currentMeter: meter,
      chartTypes,
    });
    meter ? this._getDataForMeter(meter) : this._getDataForAllMeters();

      if (meter !== null && utils.getFormattedConsumption(meter.read).toString().length > 6) {
        this.setState({isBigNumbers: true});
      }

    // this.state.currentMeter.read

    // this.state.thisMonthUsage
    // this.state.forecastUsage
    // style={utils.getFormattedConsumption(this.state.thisMonthUsage).toString().length > 6 ? {"font-size": "50px"} : {}
  };

  onTabSelected = (index) => {};

  openDefineVacation = () => {
    this.setState({ openDefineVacation: true });
  };

  closeDefineVacation = (vacation) => {
    this.setState({ openDefineVacation: false });
    if (vacation) {
      this.props.handleStateUpdate({ vacation });
    }
  };

  openMonthlyLimit = () => {
    this.setState({ openMonthlyLimit: true });
  };

  closeMonthlyLimit = (monthlyLimit) => {
    if (monthlyLimit !== undefined) {
      if (monthlyLimit === "") {
        this.setState({ openMonthlyLimit: false, monthlyLimit: null });
      } else {
        this.setState({ openMonthlyLimit: false, monthlyLimit });
      }
    } else {
      this.setState({
        openMonthlyLimit: false,
        monthlyLimit: this.state.monthlyLimit,
      });
    }
  };

  closeMeterSelection = () => {
    this.setState({ openMeterSelection: false });
  };

  onChartTypeChange = (index) => {
    this.setState({ selectedChartTypeIndex: index });
  };

  onChartTimeChange = (index) => {
    // const startDate = index === 0 ? moment(this.today).subtract(11, 'months') : this.state.dailyStartDate;
    // const endDate = index === 0 ? this.today : this.state.dailyEndDate;
    const monthlyConsumptions =
      index === 0 ? [] : this.state.monthlyConsumptions;
    const dailyConsumptions = index === 1 ? [] : this.state.dailyConsumptions;
    // const hourlyConsumptions = index === 2 ? [] : this.state.hourlyConsumptions;

    this.setState(
      {
        selectedChartTimeTypeIndex: index,
        // startDate,
        // endDate,
        monthlyConsumptions,
        dailyConsumptions,
        // hourlyConsumptions
      },
      () => {
        if (index === 0) {
          //monthly
          if (this.state.currentMeter === null) {
          api.getAllMonthlyConsumption(
            utils.getMonthsBefore(this.state.endDate, 11),
            // utils.getMonth(this.state.endDate),
            utils.getEndOfMonthDate(this.state.endDate),
            (res, err) => {
              if (err) {
                console.log("Unknown error has occurred.");
              } else {
                if (res.error) {
                  console.log(res.error);
                } else {
                  this.setState({
                    monthlyConsumptions: res.consumptionData,
                    presentationMode: res.presentationMode
                  });
                }
              }
            }
          );
          } else {
            api.getMeterMonthlyConsumption(
              this.state.currentMeter.meterCount,
              utils.getMonthsBefore(this.state.endDate, 11),
              utils.getEndOfMonthDate(this.state.endDate),
              (res, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    this.setState({
                      monthlyConsumptions: res.consumptionData,
                      presentationMode: res.presentationMode
                    });
                  }
                }
              }
            );
          }


        } else if (index === 1) {
          if (this.state.currentMeter === null) {
            api.getAllDailyConsumptionByDates(
              utils.toDateString(this.state.dailyStartDate),
              utils.toDateString(this.state.dailyEndDate),
              (res, err) => {
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    this.setState({ errorMessage: res.error });
                  } else {
                    this.setState({ dailyConsumptions: res });
                  }
                }
              }
            );
          } else {
            api.getMeterDailyConsumptionByDates(
              this.state.currentMeter.meterCount,
              utils.toDateString(this.state.dailyStartDate),
              utils.toDateString(this.state.dailyEndDate),
              (res, err) => {
                console.log('getMeterDailyConsumptionByDates', utils.toDateString(this.state.dailyStartDate), utils.toDateString(this.state.dailyEndDate), res, err);
                if (err) {
                  console.log("Unknown error has occurred.");
                } else {
                  if (res.error) {
                    console.log(res.error);
                  } else {
                    this.setState({ dailyConsumptions: res });
                  }
                }
              }
            );
          }
        }
      }
    );
  };

  _getDataForAllMeters(isUpdateThisMonth = true) {
    const { startDate, endDate } = this.state;
    if (isUpdateThisMonth) {
      api.getAllMonthlyConsumption(
        // api.getCurrentConsumption(
        utils.getMonth(new Date()),
        utils.getEndOfMonthDate(new Date()),
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              const consumptionData = res.consumptionData;

              const thisMonthUsage = Array.isArray(consumptionData) && consumptionData[consumptionData.length - 1]?.cons
              ? consumptionData[consumptionData.length - 1].cons?.toFixed(2)
              : 0;

              if (thisMonthUsage.toString().length > 7) {
                this.setState({isBigNumbers: true});
              }

              this.setState({
                thisMonthUsage,
                presentationMode: res.presentationMode
              });
            }
          }
        }
      );
    }
    //this.setState({isLoading: true});

    api.getAllMonthlyConsumption(
      //utils.getMonthsBefore(this.state.endDate, 11),
      startDate,
      // utils.getMonth(this.state.endDate),
      utils.getEndOfMonthDate(endDate),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({
              monthlyConsumptions: res.consumptionData,
              presentationMode: res.presentationMode
            });
          }
        }
      }
    );

    //Get monthly forecast
    api.getAllMonthlyForecast((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          if (res.estimatedConsumption?.toFixed(2).toString().length > 7) {
            this.setState({isBigNumbers: true});
          }

          this.setState({
            forecastUsage: res.estimatedConsumption?.toFixed(2),
          });
        }
      }
    });

    api.getAllDailyConsumptionByDates(
      utils.toDateString(this.state.dailyStartDate),
      utils.toDateString(this.state.dailyEndDate),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ dailyConsumptions: res });
          }
        }
      }
    );

    //Get all years consumption
    api.getAllMonthlyConsumption(
      // utils.getMonthsBefore(this.state.endDate, 24),
      // utils.getMonth(this.state.endDate),
      moment().subtract(24, "months").startOf("year").locale('en').format("YYYY-MM-DD"),
      moment().endOf("month").locale('en').format("YYYY-MM-DD"),
      (res, err) => {
        //this._checkFirstDataFetchCounter();
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ yearsConsumptions: res.consumptionData, presentationMode: res.presentationMode});
          }
        }
      }
    );
  }

  _getDataForMeter(meter, isUpdateThisMonth = true) {
    if (isUpdateThisMonth) {
      api.getMeterMonthlyConsumption(
        meter.meterCount,
        utils.getMonth(new Date(), 11),
        utils.getEndOfMonthDate(new Date()),
        (res, err) => {
          if (err) {
            console.log("Unknown error has occurred.");
          } else {
            if (res.error) {
              console.log(res.error);
            } else {
              const consumptionData = res.consumptionData
              const thisMonthUsage =
              Array.isArray(consumptionData) && consumptionData[consumptionData.length - 1]?.cons
                ? consumptionData[consumptionData.length - 1].cons?.toFixed(2)
                : 0

                  if (thisMonthUsage.toString().length > 6) {
                    this.setState({isBigNumbers: true});
                  }
              this.setState({
                thisMonthUsage,
                presentationMode: res.presentationMode
              });
            }
          }
        }
      );
    }

    api.getMeterDailyConsumptionByDates(
      meter.meterCount,
      utils.toDateString(this.state.dailyStartDate),
      utils.toDateString(this.state.dailyEndDate),
      (res, err) => {
        console.log('getMeterDailyConsumptionByDates', utils.toDateString(this.state.dailyStartDate), utils.toDateString(this.state.dailyEndDate), res, err);
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ dailyConsumptions: res });
          }
        }
      }
    );

    // }

    api.getMeterMonthlyConsumption(
      meter.meterCount,
      //utils.getMonthsBefore(this.state.endDate, 11),
      utils.getMonth(this.state.startDate),
      utils.getMonth(this.state.endDate),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({
              monthlyConsumptions: res.consumptionData,
              presentationMode: res.presentationMode
            });
          }
        }
      }
    );

    api.getMeterMonthlyForecast(meter.meterCount, (res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
            if (res.estimatedConsumption?.toFixed(2).toString().length > 6) {
              this.setState({isBigNumbers: true});
            }

          this.setState({
            forecastUsage: res.estimatedConsumption?.toFixed(2),
          });
        }
      }
    });

    api.getMeterMonthlyConsumption(
      meter.meterCount,
      // utils.getMonthsBefore(this.state.endDate, 24),
      moment().subtract(24, "months").startOf("year").locale('en').format("YYYY-MM-DD"),
      utils.getMonth(this.state.endDate),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ yearsConsumptions: res.consumptionData,  presentationMode: res.presentationMode});
          }
        }
      }
    );
  }

  componentWillMount() {
    this.setState({
      isLoading: true,
      startDate: utils.toDateString(this.state.startDate),
      endDate: utils.toDateString(this.state.endDate),
      vacation: this.props.vacation ? this.props.vacation : null,
    });

    //Get monthly limit
    api.getMonthlyLimit((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          this.setState({
            monthlyLimit: res && res !== "null" ? res : 0,
          });
        }
      }
    });

    //Get monthly consumption
    api.getAllMonthlyConsumption(
      // api.getCurrentConsumption(
      utils.getMonthsBefore(this.state.endDate, 11),

      // utils.getMonth(this.state.endDate),
      utils.getEndOfMonthDate(this.state.endDate),
      (res, err) => {
        //this._checkFirstDataFetchCounter();
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            const consumptionData = res.consumptionData;

            console.log('getAllMonthlyConsumption', res);
            const thisMonthUsage = Array.isArray(consumptionData) && consumptionData[consumptionData.length - 1]?.cons
            ? consumptionData[consumptionData.length - 1].cons?.toFixed(2)
            : 0;

            if (thisMonthUsage.toString().length > 7) {
              this.setState({isBigNumbers: true});
            }

            this.setState({
              monthlyConsumptions: consumptionData,
              monthlyConsumptionsForNeighborhood: consumptionData,
              thisMonthUsage,
              presentationMode: res.presentationMode
            });
          }
        }
      }
    );

    //Get all years consumption
    api.getAllMonthlyConsumption(
      // utils.getMonthsBefore(this.state.endDate, 24),
      moment().subtract(24, "months").startOf("year").locale('en').format("YYYY-MM-DD"),
      moment().endOf("month").locale('en').format("YYYY-MM-DD"),
      (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            console.log(res.error);
          } else {
            this.setState({ yearsConsumptions: res.consumptionData, presentationMode: res.presentationMode });
          }
        }
      }
    );

    //Get monthly forecast
    api.getAllMonthlyForecast((res, err) => {
      if (err) {
        console.log("Unknown error has occurred.");
      } else {
        if (res.error) {
          console.log(res.error);
        } else {
          if (res.estimatedConsumption?.toFixed(2).toString().length > 7) {
            this.setState({isBigNumbers: true});
          }

          this.setState({
            forecastUsage: res.estimatedConsumption?.toFixed(2),
          });
        }
      }
    });

        api.getAllDailyConsumptionByDates(
          utils.toDateString(this.state.dailyStartDate),
          utils.toDateString(this.state.dailyEndDate),
          (res, err) => {
            if (err) {
              console.log("Unknown error has occurred.");
            } else {
              if (res.error) {
                this.setState({ errorMessage: res.error });
              } else {
                this.setState({ dailyConsumptions: res });
              }
            }
          }
        );

  }

  _renderMobile(t, meterDesc, meterSn) {
    const { units, meters } = this.props;
    const {
      selectedChartTimeTypeIndex,
      startDate,
      endDate,
      dailyStartDate,
      dailyEndDate,
    } = this.state;
    const billingCycleText = utils.toMonthDate(new Date());

    let chartData;
    switch (this.state.selectedChartTypeIndex) {
      case 0: {
        //My
        switch (this.state.selectedChartTimeTypeIndex) {
          case 1:
            //Day
            chartData = this.state.dailyConsumptions;
            break;
          default:
            //Month
            chartData = this.state.monthlyConsumptions;
        }
        break;
      }
      case 1: {
        //Neighbor
        chartData = this.state.monthlyConsumptionsForNeighborhood;
        break;
      }
      default: {
        //Year
        chartData = this.state.monthlyConsumptionsForNeighborhood;
        break;
      }
    }
    return (
      <div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            marginTop: 8,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 15,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div className="myWaterUsage myWaterUsageMob">
                {t("BillingCycle")}
              </div>
              <div className="myWaterUsageDate myWaterUsageDateMob">
                {billingCycleText}
              </div>
            </div>
            <button
              disabled={meters.length < 2}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                flex: 1,
                marginTop: 20,
                backgroundColor: "transparent",
                cursor: meters.length < 2 ? "default" : "initial",
              }}
              onClick={() => this.setState({ openMeterSelection: true })}
            >
              <div className="waterMeterTitle">
                {t("WaterMeter") + " " + meterSn}
              </div>
              <div className="waterMeterAddress">
                <img src={images().icLocation} alt="meter location" />
                <div className="waterMeterValue">{meterDesc}</div>
              </div>
            </button>
          </div>

          <div
            className="waterUsageSepHor"
            style={{
              marginLeft: 40,
              marginRight: 40,
              marginTop: 10,
            }}
          />

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginTop: 20,
              width: "100%",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                flexWrap: 'wrap',
                margin: '0 15px',
                rowGap: 20,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div className="thisMonthLabel">{t("CurrentBillingCycle")}</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div
                    className={
                      this.state.monthlyLimit &&
                      this.state.thisMonthUsage > this.state.monthlyLimit
                        ? "monthlyLimitValueLabel monthlyLimitValueLabelMob"
                        : "thisMonthValue thisMonthValueMob"
                    }
                    style={this.state.isBigNumbers ? {"font-size": "38px", direction: 'ltr'} : {direction: 'ltr'}}

                  >
                    {this.state.thisMonthUsage}
                  </div>
                  <div
                    className="thisMonthUnits thisMonthUnitsMob"
                    style={{
                      marginLeft: 2,
                      alignSelf: "flex-end",
                      marginBottom: 8,
                    }}
                  >
                    {units?.toLowerCase()}
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginInlineStart: 20,
                }}
              >
                <div className="endOfMonthForecastLabel">
                  {t("EndOfBillingCycleForecast")}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <div className={this.getClassName()} style={this.state.isBigNumbers ? {"font-size": "38px", direction: 'ltr'} : {direction: 'ltr'}}>
                    {this.state.forecastUsage}
                  </div>
                  <div
                    className="endOfMonthForecastUnits endOfMonthForecastUnitsMob"
                    style={{
                      marginLeft: 2,
                      alignSelf: "flex-end",
                      marginBottom: 8,
                    }}
                  >
                    {units?.toLowerCase()}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div className="monthlyLimitLabel">
              {t("BillingCycleLimit") +
                " " +
                this.state.monthlyLimit +
                " " +
                units?.toLowerCase()}
            </div>
            {/*<div className="monthlyLimitContainer">*/}
            {/*    <div className="monthlyLimitValue">*/}
            {/*        {this.state.monthlyLimit}*/}
            {/*    </div>*/}
            {/*    <div className="endOfMonthForecastUnits">*/}
            {/*        {units?.toLowerCase()}*/}
            {/*    </div>*/}
            {/*</div>*/}
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              margin: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#f9f9f9",
                alignItems: "center",
                padding: 15,
                width: "100%",
              }}
            >
              <div className="getInformed">{t("getInformed")}</div>
              {this.props.vacation ? (
                <div
                  style={{
                    marginTop: 10,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <div className="vacationDates">
                    {utils.toVacationDate(this.props.vacation.startDate)} -{" "}
                    {utils.toVacationDate(this.props.vacation.endDate)}
                  </div>
                  <div style={{ display: "flex" }}>
                    <p
                      className="link"
                      onClick={this.openDefineVacation.bind(this)}
                    >
                      {t("Update")}
                    </p>
                    <p
                      className="link"
                      onClick={this.handleCancelVacation.bind(this)}
                    >
                      {t("CancelVacation")}
                    </p>
                  </div>
                </div>
              ) : (
                <button
                  className="blueButton btnDefineDays"
                  onClick={this.openDefineVacation.bind(this)}
                >
                  {t("btnDefineDays").toUpperCase()}
                </button>
              )}
            </div>
          </div>
          {this.props?.alerts.map((item, index) => {
            return (
              <div
                key={"alert" + index}
                style={{
                  display: "flex",
                  backgroundColor: globals.COLOR.BLUSH,
                  padding: 10,
                  margin: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginLeft: 16,
                    }}
                  >
                    <img
                      src={utils.getSmallIconForAlertType(item.alertTypeId)}
                      alt="alert icon"
                      style={{
                        width: 40,
                        height: 40,
                        resizeMode: "contain",
                      }}
                    />
                    <div style={{ marginLeft: 15, marginRight: 15, flex: 3 }}>
                      <div className="alertTypeLabel">
                        {t(`AlertsTitles.${AlertsTitles[+item.alertTypeId] ?? ''}`)}
                      </div>
                      <div className="alertDescription">{`${
                        item?.meterSn || ""
                      }, ${item?.fullAddress || ""}`}</div>
                      <div className="alertDate">
                        {utils.toAlertDate(item.alertTime)}
                      </div>
                      <button
                        className="seeDetailsButton"
                        onClick={() => this.onOpenAlert(item)}
                      >
                        {t("seeDetails")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            {this.state.chartTypes.map((item, index) => {
              return (
                <button
                  key={"chartSwitcher" + index}
                  onClick={() => this.onChartTypeChange(index)}
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "transparent",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      fontWeight: 400,
                      fontSize: 14,
                      color:
                        this.state.selectedChartTypeIndex === index
                          ? globals.COLOR.AZURE
                          : globals.COLOR.COOL_GRAY,
                    }}
                  >
                    {this.renderTabHeader(item, t)}
                  </div>
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      marginTop: 10,
                      height: 2,
                      backgroundColor:
                        this.state.selectedChartTypeIndex === index
                          ? globals.COLOR.AZURE
                          : globals.COLOR.COOL_GRAY,
                    }}
                  />
                </button>
              );
            })}
          </div>
          <div
            className="chartBg chartBgMobile"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {this.state.selectedChartTypeIndex === 0 && (
              <div
                style={{
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginTop: 16,
                    marginLeft: 8,
                    marginRight: 8,
                  }}
                  className="chartTypesBg"
                >
                  {this.chartTimeTypes.map((item, index) => {
                    return (
                      <button
                        key={"chartTimeSwitcher" + index}
                        style={{
                          display: "flex",
                          width: "100%",
                          height: 30,
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "transparent",
                        }}
                        onClick={() => this.onChartTimeChange(index)}
                      >
                        <div
                          className={
                            this.state.selectedChartTimeTypeIndex === index
                              ? "chartTypesBg"
                              : ""
                          }
                          style={{
                            display: "flex",
                            width: "100%",
                            height: 30,
                            fontSize: 14,
                            alignItems: "center",
                            justifyContent: "center",
                            color:
                              this.state.selectedChartTimeTypeIndex === index
                                ? "#fff"
                                : globals.COLOR.BROWNISH_GRAY,
                            backgroundColor:
                              this.state.selectedChartTimeTypeIndex === index
                                ? globals.COLOR.VIBRANT_BLUE
                                : "transparent",
                          }}
                        >
                          {t(item)}
                        </div>
                      </button>
                    );
                  })}
                </div>
                <div
                  style={{
                    display: "flex",
                    marginTop: 8,
                    justifyContent: "space-evenly",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <div className="fromTo">
                      {this.state.selectedChartTimeTypeIndex !== 2
                        ? t("from")
                        : "\n"}
                    </div>

                    <DatePicker
                      onChange={(startDate) => {
                        this.closeCalendarPicker(
                          startDate,
                          !selectedChartTimeTypeIndex
                            ? moment(endDate).diff(startDate, "months") > 12
                              ? moment(startDate).add(12, "month")
                              : moment(endDate).diff(startDate, "months") < 0
                              ? startDate
                              : endDate
                            : moment(dailyEndDate).diff(startDate, "days") > 30
                            ? moment(startDate).add(31, "days")
                            : moment(dailyEndDate).diff(startDate, "days") < 0
                            ? startDate
                            : dailyEndDate
                        );
                      }}
                      closeCalendar={false}
                      clearIcon={null}
                      locale={i18next.language}
                      maxDetail={!selectedChartTimeTypeIndex ? "year" : "month"}
                      format={
                        !selectedChartTimeTypeIndex ? "yyyy,MMM" : "yyyy,MMM, d"
                      }
                      value={utils.toDateFromString(
                        !selectedChartTimeTypeIndex ? startDate : dailyStartDate
                      )}
                      calendarIcon={
                        <img src={images().icSmallCalendar} alt="from" />
                      }
                      maxDate={new Date()}
                      minDetail={"year"}
                    />
                  </div>
                  {this.state.selectedChartTimeTypeIndex !== 2 && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <div className="fromTo">{t("to")}</div>
                      <DatePicker
                        onChange={(endDate) => {
                          this.closeCalendarPicker(
                            !selectedChartTimeTypeIndex
                              ? moment(endDate).diff(startDate, "months") > 12
                                ? moment(endDate).subtract(12, "month")
                                : startDate
                              : moment(endDate).diff(dailyStartDate, "days") >
                                30
                              ? moment(endDate).subtract(31, "days")
                              : dailyStartDate,
                            endDate
                          );
                        }}
                        clearIcon={null}
                        locale={i18next.language}
                        maxDetail={
                          !selectedChartTimeTypeIndex ? "year" : "month"
                        }
                        format={
                          !selectedChartTimeTypeIndex
                            ? "yyyy,MMM"
                            : "yyyy,MMM, d"
                        }
                        value={utils.toDateFromString(
                          !selectedChartTimeTypeIndex ? endDate : dailyEndDate
                        )}
                        calendarIcon={
                          <img src={images().icSmallCalendar} alt="to" />
                        }
                        maxDate={new Date()}
                        minDate={utils.toDateFromString(
                          !selectedChartTimeTypeIndex
                            ? startDate
                            : dailyStartDate
                        )}
                        minDetail={"year"}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
            <MeterChart
              selectedMeter={this.state.currentMeter}
              units={units}
              startDate={
                selectedChartTimeTypeIndex ? dailyStartDate : startDate
              }
              endDate={selectedChartTimeTypeIndex ? dailyEndDate : endDate}
              neighborData={this.state.neighborConsumptions}
              type={this.state.selectedChartTypeIndex}
              timeType={this.state.selectedChartTimeTypeIndex}
              data={chartData.sort(
                    (a, b) =>
                      moment(
                        a.consDate
                      ).unix() -
                      moment(b.consDate).unix()
                  )}
              monthlyLimit={this.state.monthlyLimit}
              yearsConsumptions={this.state.yearsConsumptions}
              style={{ marginTop: 16 }}
              chartShape={this.state.chartShape}
            />
          </div>

          <Popup
            contentStyle={{ borderRadius: 5, width: "80%" }}
            closeOnDocumentClick={false}
            open={this.state.openMonthlyLimit}
          >
            <MonthlyLimitPopup
              monthlyLimit={this.state.monthlyLimit}
              onClose={this.closeMonthlyLimit.bind(this)}
            />
          </Popup>

          <Popup
            contentStyle={{ borderRadius: 8, width: '95%' }}
            closeOnDocumentClick={false}
            open={this.state.openDefineVacation}
          >
            <DefineVacationPopup
              {...this.props}
              vacation={this.props.vacation}
              currentMeter={this.state.currentMeter}
              onClose={this.closeDefineVacation.bind(this)}
            />
          </Popup>

          <Popup
            contentStyle={{ borderRadius: 8, width: "80%" }}
            open={this.state.openMeterSelection}
            closeOnDocumentClick={false}
            onClose={this.closeMeterSelection.bind(this)}
          >
            <MeterSelection
              waterMeters={meters}
              onMeterSelected={this.onMeterSelected.bind(this)}
              onClose={this.closeMeterSelection.bind(this)}
            />
          </Popup>

          <Popup
            contentStyle={{ borderRadius: 8, width: "80%" }}
            closeOnDocumentClick={false}
            open={this.state.openCalendarPicker}
          >
            <CalendarRangePicker
              isMonthSelection={this.state.selectedChartTimeTypeIndex === 0}
              singleDay={this.state.selectedChartTimeTypeIndex === 2}
              startDate={utils.toDateFromString(this.state.startDate)}
              endDate={utils.toDateFromString(this.state.endDate)}
              isVacation={false}
              onClose={this.closeCalendarPicker.bind(this)}
            />
          </Popup>
        </div>
        <Popup
          contentStyle={{ borderRadius: 5 }}
          open={this.state.errorMessage}
          onClose={this.closeErrorPopup.bind(this)}
        >
          <AppPopup
            onClose={this.closeErrorPopup.bind(this)}
            message={this.state.errorMessage}
          />
        </Popup>
      </div>
    );
  }

  onOpenAlert(item) {
    this.props.history.push({
      pathname: `/alerts/${item.logId}`,
      state: { alert: item },
    });
  }

  handleCancelVacation() {
    const { vacation } = this.props;
    if (vacation) {
      api.deleteVacation(vacation.vacationID, (res, err) => {
        if (err) {
          console.log("Unknown error has occurred.");
        } else {
          if (res.error) {
            this.setState({ errorMessage: res.error });
          } else {
            this.props.handleStateUpdate({ vacation: null });
          }
        }
      });
    }
  }

  render() {
    const { meters, t } = this.props;
    let meterDesc = "";
    let meterSn = "";
    if (!this.state.currentMeter && meters?.length < 2) {
      meterDesc = meters[0] ? meters[0]?.fullAddress : "";
      meterSn = meters[0]?.meterSn || "";
    } else if (!this.state.currentMeter) {
      meterDesc = `${t("AllWaterMeters")} (${meters.length})`;
      meterSn = "";
    } else {
      const meter = this.state.currentMeter;
      meterDesc = meter?.fullAddress;
      meterSn = meter?.meterSn || "";
    }
    return (
      <div>
        <Mobile>
          {(matches) => {
            if (matches)
              return this._renderMobile(this.props.t, meterDesc, meterSn);
            else return this._renderDesktop(this.props.t, meterDesc, meterSn);
          }}
        </Mobile>
      </div>
    );
  }
}

export default withTranslation("translations")(Home);
