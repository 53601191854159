import i18next from "../i18n";
import moment from "moment";

export const changeLanguage = async (language) => {
    await i18next.changeLanguage(language);

    moment.locale(language);

    if (language === 'ar') {
        const symbolMap = {
            '1': '1',
            '2': '2',
            '3': '3',
            '4': '4',
            '5': '5',
            '6': '6',
            '7': '7',
            '8': '8',
            '9': '9',
            '0': '0'
        };
        const numberMap = {
            '١': '1',
            '٢': '2',
            '٣': '3',
            '٤': '4',
            '٥': '5',
            '٦': '6',
            '٧': '7',
            '٨': '8',
            '٩': '9',
            '٠': '0'
        }
        moment.updateLocale('ar', {
            preparse: function (string) {
                return string.replace(/\u200f/g, '').replace(/[١٢٣٤٥٦٧٨٩٠]/g, function (match) {
                    return numberMap[match];
                }).replace(/،/g, ',');
            },
            postformat: function(string) {
                return string.replace(/\d/g, function(match) {
                    return symbolMap[match];
                }).replace(/,/g, '،');
            },
        });
    }

    window.interdeal.SetLocale(language);
    // window.location.reload();
};
