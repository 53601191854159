import React, {Component} from "react";

import './LanguageSelector.css';
import images from "../../images";
import translations from "../../translations";
import {changeLanguage} from "../../utils/changeLanguage";
import i18next from "../../i18n";

export class LanguageSelector extends Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            selectedLanguage: i18next.language,
        };
    }

    toggleOpen = (event) => {
        event.stopPropagation();

        this.setState(state => ({open: !state.open}));
    }

    handleLanguageSelected = (lang) => {
        if (this.state.selectedLanguage === lang) {
            this.setState({open: false});

            return;
        }

        changeLanguage(lang);

        this.setState({ open: false, selectedLanguage: lang });
    }

    render() {
        const {open, selectedLanguage} = this.state;

        const { icMenu } = images();

        return (
            <div className="language-selector">
                <button className="language-selector__image" onClick={this.toggleOpen}>
                    <img src={icMenu} alt="lang selector menu" />
                </button>
                <div className={`language-selector__dropdown ${open ? 'language-selector__dropdown_opened' : ''}`}>
                    {translations.languages.map((item, i) => (
                        <button key={`lang-${item.lang}`} className="language-item" onClick={() => this.handleLanguageSelected(item.lang)}>
                            <span className={`language-item__text ${selectedLanguage === item.lang ? 'language-item_active' : ''} `}>{item.lang.replace(/^./, (str) => str.toUpperCase())}</span>
                        </button>
                    ))}
                </div>
            </div>
        )
    }
}
