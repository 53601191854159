import React from "react";
import { withTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import translations from "../translations";

class Phone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {}

  render() {
    const {onChange, phone, country, style} = this.props;

    return (
      <>
        <PhoneInput
          country={country ?? translations.phoneCountries[0].label}
          onlyCountries={translations.phoneCountries.map(country => country.label)}
          value={phone}
          onChange={(value, country) => onChange(value, country)}
          inputProps={{
            name: "phone",
            required: true,
            autoFocus: true,
          }}
          inputStyle={{ width: 375, height: 46, ...style }}
          countryCodeEditable={false}
        />
      </>
    );
  }
}

export default withTranslation("translations")(Phone);
