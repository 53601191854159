/* eslint-disable */
import React from 'react';
import {withTranslation} from "react-i18next";
import i18next from "i18next";

import './Register.css';
import images from '../../images';
import '../../App.css';
import * as api from "../../api";
import globals from "../../globals";
import {Mobile} from "../../App";
import DataManager from "../../managers/DataManager";

class Register extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchTerm: '',
            utilities: [],
        }
    }

    componentWillMount() {
        if (this.props.onEnter)
            this.props.onEnter(false);
    }

    render() {
        const {t} = this.props;
        return (
            <div>
                <Mobile>
                    {matches => {
                        if (matches)
                            return this._renderMobile(t);
                        else
                            return this._renderDesktop(t);
                    }}
                </Mobile>
            </div>
        );
    }

    _renderMobile(t) {
        return (
            <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                marginLeft: 20,
                marginRight: 20,
            }}>
                <h1 className="title titleMobile" style={{fontSize: 17}}>{t('JoinMyWaterAdvisor')}</h1>
                <div style={{marginTop: 10, display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <button className="signUpCellMobile"
                            style={{
                                padding: 16,
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%'
                            }}
                            onClick={this.goToRegisterPhone.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex', width: '100%'}}>
                            <p style={{padding: 0, margin: 0, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Sign-up-with-cell-phone-number">{t('signUpWithPhone')}</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">{t('PhoneNumberRegistered')}</p>
                        </div>
                        <div>
                            <img src={images().icArrowRightBlue} style={{width: 16, height: 16}}/>
                        </div>
                    </button>
                    <button className="signUpCellMobile"
                            style={{
                                marginTop: 10,
                                padding: 16,
                                alignItems: 'center',
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%'
                            }}
                            onClick={this.goToRegisterAccount.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex', width: '100%'}}>
                            <p style={{padding: 0, margin: 0, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Sign-up-with-cell-phone-number">{t('signUpWithAccountNumber')}</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">{t('billAccountNumber')}</p>
                        </div>
                        <div>
                            <img src={images().icArrowRightBlue} style={{width: 16, height: 16}}/>
                        </div>
                    </button>
                </div>
                {DataManager.sharedInstance.loginErrors.phone * DataManager.sharedInstance.loginErrors.account > 0
                &&  <p style={{marginTop: 30, justifyContent: 'center'}}
                       className="Need-help-Call-your-utility-office">לתמיכה טכנית בתקלות ניתן לפנות למרכז שירות ארד 1700-50-65-65</p>}
                <div style={{marginTop: 10}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
            </div>
        );
    }

    _renderDesktop(t) {
        return (
            <div style={{flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 20}}>
                <h1 className={"title"}>{t('JoinMyWaterAdvisor')}</h1>
                <div style={{marginTop: 20}}>
                    <button className="signUpCell"
                            style={{
                                padding: 16,
                                alignItems: 'center',
                                display: 'flex',
                                height: 'auto',
                                justifyContent: "space-between"
                            }}
                            onClick={this.goToRegisterPhone.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex'}}>
                            <p style={{padding: 0, margin: 0, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Sign-up-with-cell-phone-number">{t('signUpWithPhone')}</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">{t('PhoneNumberRegistered')}</p>
                        </div>
                        <div>
                            <img src={images().icArrowRightBlue} style={{
                                alignItems: 'flex-end',
                                display: 'flex',

                            }}/>
                        </div>
                    </button>
                    <button className="signUpCell"
                            style={{
                                marginTop: 10,
                                padding: 16,
                                alignItems: 'center',
                                display: 'flex',
                                height: 'auto',
                                justifyContent: "space-between"
                            }}
                            onClick={this.goToRegisterAccount.bind(this)}>
                        <div style={{flexDirection: 'column', display: 'flex'}}>
                            <p style={{padding: 0, margin: 0, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Sign-up-with-cell-phone-number">{t('signUpWithAccountNumber')}</p>
                            <p style={{padding: 0, margin: 0, marginTop: 10, textAlign: i18next.dir() === 'rtl' ? 'right' : 'left'}}
                               className="Phone-number-registered-with-your-utility-or-servi">{t('billAccountNumber')}</p>
                        </div>
                        <div>
                            <img src={images().icArrowRightBlue} style={{
                                alignItems: 'flex-end',
                                display: 'flex',
                                float: 'right'
                            }}/>
                        </div>
                    </button>
                </div>
                {DataManager.sharedInstance.loginErrors.phone * DataManager.sharedInstance.loginErrors.account > 0
                &&  <p style={{marginTop: 40, justifyContent: 'center'}}
                   className="Need-help-Call-your-utility-office">לתמיכה טכנית בתקלות ניתן לפנות למרכז שירות ארד 1700-50-65-65</p>}
                <div style={{marginTop: 10, width: 320}}>
                    {this.state.utilities.map(item => {
                        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                            <p style={{color: globals.COLOR.DUSK, fontSize: 16}}>{item.description}</p>
                            <p style={{
                                color: globals.COLOR.AZURE,
                                fontSize: 15,
                                marginLeft: 10
                            }}>{item.phoneNumber}</p>
                        </div>
                    })}
                </div>
            </div>
        );
    }

    goToRegisterAccount = () => {
        this.props.history.push('/register/account');
    };

    goToRegisterPhone = () => {
        this.props.history.push('/register/phone');
    };

    onChangeUtilityName = (event) => {
        //this.state.searchTerm = value;
        const term = event.target.value;
        if (term.length >= 2) {
            api.searchUtilities(term, (res, err) => {
                if (!err) {
                    this.setState({utilities: res});
                }
            })
        } else if (term.length === 0) {
            this.setState({utilities: []});
        }
    };
}

export default withTranslation('translations')(Register)

